var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.confirmLoading } },
    [
      _c(
        "j-form-container",
        { attrs: { disabled: _vm.formDisabled } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                slot: "detail",
                model: _vm.model,
                rules: _vm.validatorRules
              },
              slot: "detail"
            },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "新闻标题",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "title"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入新闻标题" },
                            model: {
                              value: _vm.model.title,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "title", $$v)
                              },
                              expression: "model.title"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "新闻类型",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "type"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择新闻类型" },
                              model: {
                                value: _vm.model.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "type", $$v)
                                },
                                expression: "model.type"
                              }
                            },
                            [
                              _c("a-select-option", { attrs: { value: "1" } }, [
                                _vm._v(
                                  "\n                 赛事通知\n                "
                                )
                              ]),
                              _c("a-select-option", { attrs: { value: "2" } }, [
                                _vm._v(
                                  "\n                 安全教育\n                "
                                )
                              ]),
                              _c("a-select-option", { attrs: { value: "3" } }, [
                                _vm._v(
                                  "\n                 其他\n                "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "新闻内容",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "content"
                          }
                        },
                        [
                          _c("j-editor", {
                            model: {
                              value: _vm.model.content,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "content", $$v)
                              },
                              expression: "model.content"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "生效开始时间",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "startTime"
                          }
                        },
                        [
                          _c("j-date", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请选择生效开始时间",
                              "show-time": true,
                              "date-format": "YYYY-MM-DD HH:mm:ss"
                            },
                            model: {
                              value: _vm.model.startTime,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "startTime", $$v)
                              },
                              expression: "model.startTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "生效结束时间",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "endTime"
                          }
                        },
                        [
                          _c("j-date", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请选择生效结束时间",
                              "show-time": true,
                              "date-format": "YYYY-MM-DD HH:mm:ss"
                            },
                            model: {
                              value: _vm.model.endTime,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "endTime", $$v)
                              },
                              expression: "model.endTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "发布状态",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "isPublish"
                          }
                        },
                        [
                          _c("j-dict-select-tag", {
                            attrs: {
                              type: "list",
                              dictCode: "send_status",
                              placeholder: "请选择发布状态",
                              disabled: ""
                            },
                            model: {
                              value: _vm.model.isPublish,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "isPublish", $$v)
                              },
                              expression: "model.isPublish"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "状态",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "isEnable"
                          }
                        },
                        [
                          _c("j-dict-select-tag", {
                            attrs: {
                              type: "radio",
                              dictCode: "status",
                              placeholder: "请选择状态"
                            },
                            model: {
                              value: _vm.model.isEnable,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "isEnable", $$v)
                              },
                              expression: "model.isEnable"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "置顶",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "isTop"
                          }
                        },
                        [
                          _c("j-dict-select-tag", {
                            attrs: {
                              type: "radio",
                              dictCode: "is_top",
                              placeholder: "请选择置顶"
                            },
                            model: {
                              value: _vm.model.isTop,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "isTop", $$v)
                              },
                              expression: "model.isTop"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }