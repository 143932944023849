var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c(
            "a-form-model",
            { ref: "form", attrs: { model: _vm.model, rules: _vm.rules } },
            [
              _c(
                "a-tabs",
                [
                  _c(
                    "a-tab-pane",
                    { key: "1", attrs: { tab: "消息选项" } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "测试APP",
                            prop: "app",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              placeholder: "请选择测试APP",
                              options: _vm.appOptions
                            },
                            model: {
                              value: _vm.model.app,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "app", $$v)
                              },
                              expression: "model.app"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "发送给所有人",
                            prop: "sendAll",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol
                          }
                        },
                        [
                          _c("a-switch", {
                            attrs: {
                              checkedChildren: "是",
                              unCheckedChildren: "否"
                            },
                            on: { change: _vm.onSendAllChange },
                            model: {
                              value: _vm.model.sendAll,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "sendAll", $$v)
                              },
                              expression: "model.sendAll"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "接收人",
                            prop: "receiver",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol
                          }
                        },
                        [
                          _c("j-select-multi-user", {
                            attrs: {
                              disabled: _vm.model.sendAll,
                              placeholder: "请选择接收人"
                            },
                            model: {
                              value: _vm.model.receiver,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "receiver", $$v)
                              },
                              expression: "model.receiver"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "消息内容",
                            prop: "content",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol
                          }
                        },
                        [
                          _c("a-textarea", {
                            attrs: { rows: 5, placeholder: "请输入消息内容" },
                            model: {
                              value: _vm.model.content,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "content", $$v)
                              },
                              expression: "model.content"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: { width: "120px" },
                              attrs: { type: "primary", size: "large" },
                              on: { click: _vm.onSend }
                            },
                            [_vm._v("发送")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-tabs",
                [
                  _c(
                    "a-tab-pane",
                    { key: "1", attrs: { tab: "测试结果（刷新自动清空）" } },
                    [
                      _c("a-table", {
                        attrs: {
                          rowKey: "id",
                          bordered: "",
                          size: "middle",
                          columns: _vm.columns,
                          dataSource: _vm.dataSource
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "action",
                            fn: function(text, record) {
                              return _c(
                                "div",
                                {},
                                [
                                  record.app === "DINGTALK"
                                    ? [
                                        !record.recalled
                                          ? _c(
                                              "a-popconfirm",
                                              {
                                                attrs: { title: "确定吗？" },
                                                on: {
                                                  confirm: function($event) {
                                                    return _vm.handleRecall(
                                                      record
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function($event) {}
                                                    }
                                                  },
                                                  [_vm._v("撤回")]
                                                )
                                              ]
                                            )
                                          : _c("span", [_vm._v("已撤回")])
                                      ]
                                    : [_vm._v("-")]
                                ],
                                2
                              )
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }