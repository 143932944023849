var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      staticStyle: { height: "100%" },
      attrs: {
        title: _vm.title,
        maskClosable: true,
        width: _vm.drawerWidth,
        placement: "right",
        closable: true,
        visible: _vm.visible
      },
      on: { close: _vm.handleCancel }
    },
    [
      _c("template", { slot: "title" }, [
        _c("div", { staticStyle: { width: "100%" } }, [
          _c("span", [_vm._v(_vm._s(_vm.title))]),
          _c(
            "span",
            {
              staticStyle: {
                display: "inline-block",
                width: "calc(100% - 51px)",
                "padding-right": "10px",
                "text-align": "right"
              }
            },
            [
              _c("a-button", {
                staticStyle: { height: "20px", width: "20px", border: "0px" },
                attrs: { icon: "appstore" },
                on: { click: _vm.toggleScreen }
              })
            ],
            1
          )
        ])
      ]),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: { model: _vm.model, rules: _vm.validatorRules }
            },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "用户账号",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "username"
                  }
                },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: "请输入用户账号",
                      readOnly: !!_vm.model.id
                    },
                    model: {
                      value: _vm.model.username,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "username", $$v)
                      },
                      expression: "model.username"
                    }
                  })
                ],
                1
              ),
              !_vm.model.id
                ? [
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: "登录密码",
                          labelCol: _vm.labelCol,
                          wrapperCol: _vm.wrapperCol,
                          prop: "password"
                        }
                      },
                      [
                        _c("a-input", {
                          attrs: {
                            type: "password",
                            placeholder: "请输入登录密码"
                          },
                          model: {
                            value: _vm.model.password,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "password", $$v)
                            },
                            expression: "model.password"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: "确认密码",
                          labelCol: _vm.labelCol,
                          wrapperCol: _vm.wrapperCol,
                          prop: "confirmpassword"
                        }
                      },
                      [
                        _c("a-input", {
                          attrs: {
                            type: "password",
                            placeholder: "请重新输入登录密码"
                          },
                          on: { blur: _vm.handleConfirmBlur },
                          model: {
                            value: _vm.model.confirmpassword,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "confirmpassword", $$v)
                            },
                            expression: "model.confirmpassword"
                          }
                        })
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "用户姓名",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "realname"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入用户姓名" },
                    model: {
                      value: _vm.model.realname,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "realname", $$v)
                      },
                      expression: "model.realname"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "工号",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "workNo"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入工号" },
                    model: {
                      value: _vm.model.workNo,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "workNo", $$v)
                      },
                      expression: "model.workNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "手机号码",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "phone"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入手机号码" },
                    model: {
                      value: _vm.model.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "phone", $$v)
                      },
                      expression: "model.phone"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.roleDisabled,
                      expression: "!roleDisabled"
                    }
                  ],
                  attrs: {
                    label: "角色分配",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol
                  }
                },
                [
                  _c("j-multi-select-tag", {
                    attrs: {
                      disabled: _vm.disableSubmit,
                      options: _vm.rolesOptions,
                      placeholder: "请选择角色"
                    },
                    model: {
                      value: _vm.model.selectedroles,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "selectedroles", $$v)
                      },
                      expression: "model.selectedroles"
                    }
                  })
                ],
                1
              ),
              _vm.userInfo.username == "ylpp" ||
              _vm.userInfo.username == "admin"
                ? _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.departDisabled,
                          expression: "!departDisabled"
                        }
                      ],
                      attrs: {
                        label: "组织分配",
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol
                      }
                    },
                    [
                      _c("j-multi-select-tag", {
                        attrs: {
                          disabled: _vm.disableSubmit,
                          options: _vm.tenantsOptions,
                          placeholder: "请选择组织"
                        },
                        model: {
                          value: _vm.model.relTenantIds,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "relTenantIds", $$v)
                          },
                          expression: "model.relTenantIds"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "头像",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol
                  }
                },
                [
                  _c("j-image-upload", {
                    staticClass: "avatar-uploader",
                    attrs: { text: "上传" },
                    model: {
                      value: _vm.model.avatar,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "avatar", $$v)
                      },
                      expression: "model.avatar"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "生日",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol
                  }
                },
                [
                  _c("a-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "请选择生日",
                      format: _vm.dateFormat,
                      getCalendarContainer: function(node) {
                        return node.parentNode
                      }
                    },
                    model: {
                      value: _vm.model.birthday,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "birthday", $$v)
                      },
                      expression: "model.birthday"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "性别",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        placeholder: "请选择性别",
                        getPopupContainer: function(target) {
                          return target.parentNode
                        }
                      },
                      model: {
                        value: _vm.model.sex,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "sex", $$v)
                        },
                        expression: "model.sex"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("男")
                      ]),
                      _c("a-select-option", { attrs: { value: 2 } }, [
                        _vm._v("女")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "邮箱",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "email"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入邮箱" },
                    model: {
                      value: _vm.model.email,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "email", $$v)
                      },
                      expression: "model.email"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "座机",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "telephone"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入座机" },
                    model: {
                      value: _vm.model.telephone,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "telephone", $$v)
                      },
                      expression: "model.telephone"
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.disableSubmit,
              expression: "!disableSubmit"
            }
          ],
          staticClass: "drawer-bootom-button"
        },
        [
          _c(
            "a-popconfirm",
            {
              attrs: {
                title: "确定放弃编辑？",
                okText: "确定",
                cancelText: "取消"
              },
              on: { confirm: _vm.handleCancel }
            },
            [
              _c("a-button", { staticStyle: { "margin-right": ".8rem" } }, [
                _vm._v("取消")
              ])
            ],
            1
          ),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.confirmLoading },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }